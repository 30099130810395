import React, { useState } from "react";
import {MdKeyboardArrowDown} from '../assets/icons/vander'

export default function Faq(){
    const [activeIndex, setActiveIndex] = useState(1)
    const accordionData = [
        {
            id: 1,
            title: 'Como o Class2Data utiliza IA para analisar as videoaulas?',
            desc: 'O Class2Data aplica inteligência artificial para avaliar a qualidade das videoaulas, identificando pontos de melhoria e gerando relatórios detalhados que ajudam a aprimorar o ensino.'
        },
        {
            id: 2,
            title: 'É possível personalizar os relatórios gerados pelo Class2Data?',
            desc: 'Sim, nossos relatórios são totalmente personalizáveis para atender às necessidades específicas de cada instituição, garantindo que os dados façam sentido para sua operação.'
        },
        {
            id: 3,
            title: 'Quais tipos de insights são fornecidos pelo Class2Data?',
            desc: 'O Class2Data oferece insights sobre a clareza das explicações, análise de temas sensíveis, tom emocional e muito mais, permitindo que você tome decisões informadas.'
        },
        {
            id: 4,
            title: 'Como o Class2Data pode ajudar a reduzir os custos operacionais?',
            desc: 'Ao automatizar o monitoramento das aulas e gerar relatórios de forma rápida e eficiente, o Class2Data reduz a necessidade de supervisão manual, otimizando processos e diminuindo custos operacionais.'
        }
    ]
    return(
        <>
        <div className="container relative mt-2 mb-8">
                <div className="grid lg:grid-cols-12 md:grid-cols-2 grid-cols-1 items-center md:gap-[30px]">
                    <div className="lg:col-span-4 md:mb-0 mb-8">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Ficou com dúvidas?</h3>
                        <p className="text-slate-400 max-w-xl mx-auto mb-6">Veja como nossa inteligência artificial pode te ajudar.</p>
                    </div>

                    <div className="lg:col-span-8 md:mt-0 mt-8">
                        {accordionData.map((item,index)=>{
                            return(
                                <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4" key={index}>
                                    <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                        <button type="button" onClick={()=>setActiveIndex(item.id)} className={`${activeIndex === item.id ? "bg-gray-50 dark:bg-slate-800 text-amber-400" : ""} flex justify-between items-center p-5 w-full font-medium text-start`}>
                                            <span>{item.title}</span>
                                            <MdKeyboardArrowDown className={`${activeIndex === item.id ? "rotate-180" : ""} w-4 h-4 shrink-0`}/>
                                        </button>
                                    </h2>
                                    <div className={activeIndex === item.id ? "" : "hidden"}>
                                        <div className="p-5">
                                            <p className="text-slate-400 dark:text-gray-400">{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div> 
        </>
    )
}