import "./assets/css/tailwind.css";
import "./assets/css/materialdesignicons.min.css";
import { Route, Routes } from "react-router-dom";
import Index from "./pages/index";
import Terms from "./pages/terms";
import Privacy from "./pages/privacy";
import Error from "./pages/error";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/termos-de-uso" element={<Terms />} />
      <Route path="/politica-de-privacidade" element={<Privacy />} />
      <Route path="/error" element={<Error />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}

export default App;
