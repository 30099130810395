import React from "react";
import aboutImg from "../assets/images/features/2.jpg"

import {FiCheckCircle} from '../assets/icons/vander'

export default function AboutOne(){
    return(
        <>
        <div className="container relative md:mt-24 mt-16">
            <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 ps-6 pt-6 lg:me-8">
                    <img src={aboutImg} className="ltr:rounded-tl-lg rtl:rounded-tr-lg" alt=""/>
                </div>

                <div className="">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Relatórios Personalizados com IA para Aulas Online</h3>
                    <p className="text-slate-400 max-w-xl">"Com o Class2Data, criamos relatórios detalhados e adaptados à nossa operação, garantindo que cada dado faça sentido para nossa gestão"</p>

                    <ul className="list-none text-slate-400 mt-4">
                        <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/>Insights completos e relevantes para a sua instituição.</li>
                        <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/>Escolha o que será analisado</li>
                        <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/>Adapte os relatórios às suas necessidades específicas.</li>
                        <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/>Tome decisões informadas com base em dados precisos.</li>
                    </ul>
                </div>
            </div>
        </div>
        </>
    )
}