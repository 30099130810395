import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoDark from '../assets/images/logo-class.svg'

export default function Navbar(){
    const [scroll, setScroll] = useState(false);

    useEffect(()=>{
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
          });
    },[])

if (document.getElementById("navigation")) {
    var elements = document.getElementById("navigation").getElementsByTagName("a");
    for (var i = 0, len = elements.length; i < len; i++) {
        elements[i].onclick = function (elem) {
            if (elem.target.getAttribute("href") === "#") {
                var submenu = elem.target.nextElementSibling.nextElementSibling;
                submenu.classList.toggle('open');
            }
        }
    }
}

    return(
        <>
        <nav id="topnav" className={`${scroll ? "nav-sticky" : "" } defaultscroll is-sticky`}>
            <div className="container">
                <Link className="logo" to="/">
                    <img src={logoDark} className="inline-block" style={{height: '24px'}} alt=""/>
                </Link>
               
                <ul className="buy-button list-none mb-0">
                    <li className="inline ps-1 mb-0">
                        <Link to="https://wa.me/5511967136492?text=Gostaria%20de%20informa%C3%A7%C3%B5es" rel="noreferrer" target="_blank" className="py-[6px] px-6 inline-block items-center justify-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400 hover:bg-amber-500 border border-amber-400 hover:border-amber-500 text-white font-semibold">Teste grátis</Link>
                    </li>
                </ul>
            </div>
        </nav>
        </>
    )
}