import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

export default function Privacy() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Navbar />
      <section className="relative md:pt-44 pt-32 pb-8 bg-gradient-to-b from-amber-400/20 dark:from-amber-400/40 to-transparent">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold mb-0">
                Políticas de privacidade
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="md:w-3/4">
              <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                <h5 className="text-xl font-semibold mb-4">
                  Política de privacidade :
                </h5>
                <p className="text-slate-400">
                  A Class2Data se compromete a proteger a privacidade dos dados
                  dos usuários, garantindo segurança e transparência no
                  processamento das informações coletadas. Este documento
                  detalha como coletamos, utilizamos e armazenamos os dados de
                  nossas análises.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  1. Coleta de Informações
                </h5>
                <p className="text-slate-400">
                  A Class2Data coleta dados de aulas para avaliar o conteúdo e
                  garantir a conformidade com as políticas da empresa. Essas
                  informações incluem gravações, transcrições e outras
                  informações contextuais relevantes. Coletamos somente os dados
                  necessários para cumprir nossas finalidades.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  2. Uso das Informações
                </h5>
                <p className="text-slate-400">
                  Os dados coletados são processados com tecnologias de
                  inteligência artificial da OpenAI para:
                  <p>- Avaliação da qualidade das aulas.</p>
                  <p>
                    - Análise do conteúdo para garantir a conformidade com
                    políticas empresariais.
                  </p>
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  3. Armazenamento de Dados
                </h5>
                <p className="text-slate-400">
                  Todos os dados processados são armazenados em servidores
                  seguros na Amazon Web Services (AWS), respeitando os mais
                  altos padrões de segurança e conformidade. Retemos os dados
                  apenas pelo período necessário para cumprir os propósitos para
                  os quais foram coletados, exceto quando a retenção por um
                  período mais longo for exigida por lei.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  4. Compartilhamento de Informações
                </h5>
                <p className="text-slate-400">
                  A Class2Data não compartilha as informações dos usuários com
                  terceiros, exceto para os fins específicos de processamento da
                  análise de conteúdo com a OpenAI, ou conforme exigido por lei.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  5. Segurança das Informações
                </h5>
                <p className="text-slate-400">
                  Implementamos medidas de segurança adequadas para proteger as
                  informações dos usuários contra acesso, alteração, divulgação
                  ou destruição não autorizada. Monitoramos regularmente nossos
                  sistemas para detectar possíveis vulnerabilidades.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  6. Direitos dos Usuários
                </h5>
                <p className="text-slate-400">
                  Os usuários têm o direito de acessar, corrigir ou solicitar a
                  exclusão de seus dados. Para fazer uma solicitação ou para
                  obter mais informações sobre o tratamento dos dados, entre em
                  contato conosco.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  7. Alterações na Política de Privacidade
                </h5>
                <p className="text-slate-400">
                  A Class2Data pode revisar esta política periodicamente.
                  Qualquer alteração será publicada em nossa plataforma, e os
                  usuários serão notificados.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">8. Contato</h5>
                <p className="text-slate-400">
                  Para dúvidas ou solicitações sobre esta política de
                  privacidade, entre em contato conosco pelo nosso canal de
                  suporte.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
