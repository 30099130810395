import React from "react";
import { FiAirplay, PiPlugBold, FiGlobe, FiLayout,FiLifeBuoy, AiFillRocket } from "../assets/icons/vander";

export default function AiFeatures(){
    const featureData = [
        {
            icon:AiFillRocket,
            title:'Escalabilidade',
            desc: 'Não importa se você vai analisar 100 ou 10000 aulas, nossa estrutura foi desenvolvida para processar tudo sem demora ou gargalos.'
        },
        {
            icon:FiAirplay,
            title:'Painel Avançado',
            desc: 'Visualize os principais <span class="Text-Strong">indicadores de performance</span> das suas aulas em um painel intuitivo, com dados que facilitam a tomada de decisão.'
        },
        {
            icon:PiPlugBold,
            title: 'Integrações Inteligentes',
            desc: 'Class2Data possui uma api moderna e com <span class="Text-Strong">webhooks</span> para envio de notificações e relatórios de forma automatica em tempo real.'
        },
        {
            icon:FiGlobe,
            title: 'Análise Multilíngue',
            desc: 'Realize análises em <span class="Text-Strong">vários idiomas</span>, permitindo o monitoramento de aulas em diferentes contextos globais.'
        },
        {
            icon:FiLayout,
            title: 'Relatórios Personalizados',
            desc: 'Crie relatórios adaptados às necessidades da sua instituição, destacando os <span class="Text-Strong">pontos que mais importam</span> para o seu crescimento.'
        },
        {
            icon:FiLifeBuoy,
            title: 'Implantação Personalizada',
            desc: 'A implantação do sistema de acordo com a <span class="Text-Strong">operação da sua instituição</span>, garantindo resultados eficientes desde o início.'
        },
    ]
    return(
        <>
        <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Somos uma IA criada para o monitoramento educacional.</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">O Class2Data vai além de uma simples análise, permitindo que você adapte as verificações, alertas e validações de acordo com as necessidades da sua instituição de ensino.</p>
                </div>

                <div className="grid md:grid-cols-3 grid-cols-1 mt-6 gap-6">
                    {featureData.map((item,index)=>{
                        const Icon = item.icon
                        return(
                            <div className="group flex duration-500 xl:p-3" key={index}>
                                <div className="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-amber-400/5 group-hover:bg-amber-400 group-hover:text-white border-2 border-amber-400/20 text-amber-400 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 duration-500">
                                    <Icon className="w-5 h-5"/>
                                </div>
                                <div className="flex-1 ms-4">
                                    <h4 className="mb-0 text-lg font-semibold">{item.title}</h4>
                                    <p className="text-slate-400 mt-2" dangerouslySetInnerHTML={{ __html: item.desc }}></p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
