import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Navbar from "../components/navbar";
import Footer from "../components/footer";

import { MdKeyboardArrowDown } from "../assets/icons/vander";

export default function Terms() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Navbar />
      <section className="relative md:pt-44 pt-32 pb-8 bg-gradient-to-b from-amber-400/20 dark:from-amber-400/40 to-transparent">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold mb-0">
                Termos de uso
              </h5>
            </div>
          </div>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="md:w-3/4">
              <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                <h5 className="text-xl font-semibold mb-4">Termos de uso:</h5>
                <p className="text-slate-400">
                  Bem-vindo à Class2Data! Ao acessar e utilizar a nossa
                  plataforma, você concorda com os seguintes Termos de Uso.
                  Recomendamos a leitura cuidadosa deste documento antes de
                  iniciar o uso dos nossos serviços.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  1. Aceitação dos Termos
                </h5>
                <p className="text-slate-400">
                  Ao utilizar a plataforma Class2Data, você concorda com os
                  Termos de Uso descritos neste documento e se compromete a
                  cumpri-los. Se você não concordar com estes termos, não deve
                  utilizar a plataforma.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  2. Descrição dos Serviços
                </h5>
                <p className="text-slate-400">
                  A Class2Data oferece uma plataforma que coleta dados de aulas
                  para análise do teor dos conteúdos. Com o auxílio de
                  tecnologia de inteligência artificial, os conteúdos são
                  analisados para:
                  <p>Gerar relatórios de qualidade às aulas.</p>
                  <p>
                    Verificar a conformidade dos temas com as políticas da
                    empresa. Os dados e relatórios gerados podem ser acessados
                    por meio de um dashboard, com armazenamento seguro na AWS.
                  </p>
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  3. Responsabilidade do Usuário
                </h5>
                <p className="text-slate-400">
                  <p>
                    Informações Fornecidas: O usuário é responsável pela
                    veracidade e precisão das informações fornecidas para
                    análise na plataforma.
                  </p>
                  <p>
                    Conformidade com Políticas: Ao enviar dados, o usuário
                    declara que possui os direitos necessários para o uso dos
                    materiais e garante que os conteúdos analisados respeitam as
                    leis e normas aplicáveis.
                  </p>
                  <p>
                    Acesso e Segurança: O usuário deve proteger suas credenciais
                    de acesso e notificar imediatamente a Class2Data em caso de
                    qualquer atividade suspeita em sua conta.
                  </p>
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  4. Uso Permitido e Proibições
                </h5>
                <p className="text-slate-400">
                  A Class2Data concede ao usuário uma licença limitada,
                  revogável e não exclusiva para utilizar a plataforma de acordo
                  com estes Termos de Uso. É estritamente proibido:
                  <p>
                    Utilizar a plataforma para atividades ilícitas ou contrárias
                    às políticas empresariais.
                  </p>
                  <p>
                    Reproduzir, duplicar ou revender os relatórios e dados
                    gerados para fins comerciais não autorizados.
                  </p>
                  <p>
                    Interferir no funcionamento da plataforma ou tentar acessar
                    dados de outros usuários.
                  </p>
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  5. Propriedade Intelectual
                </h5>
                <p className="text-slate-400">
                  A Class2Data retém todos os direitos de propriedade
                  intelectual sobre a plataforma, incluindo, mas não se
                  limitando, ao software, design, textos e conteúdo. É vedado o
                  uso desses materiais para qualquer fim não autorizado.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  6. Privacidade e Proteção de Dados
                </h5>
                <p className="text-slate-400">
                  A Class2Data se compromete a proteger a privacidade dos dados
                  dos usuários, conforme descrito em nossa Política de
                  Privacidade. Os dados coletados são utilizados exclusivamente
                  para as finalidades da plataforma e armazenados de forma
                  segura na AWS.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  7. Limitação de Responsabilidade
                </h5>
                <p className="text-slate-400">
                  A Class2Data não se responsabiliza por quaisquer danos diretos
                  ou indiretos decorrentes do uso da plataforma ou dos
                  relatórios gerados, incluindo perda de dados, lucro cessante
                  ou outras perdas decorrentes de falhas ou indisponibilidade
                  temporária da plataforma.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  8. Modificações nos Termos
                </h5>
                <p className="text-slate-400">
                  A Class2Data se reserva o direito de modificar estes Termos de
                  Uso a qualquer momento. Notificaremos os usuários sobre
                  mudanças significativas e, ao continuar a utilizar a
                  plataforma, você aceita os termos atualizados.
                </p>
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  9. Encerramento de Acesso
                </h5>
                <p className="text-slate-400">
                  A Class2Data pode, a seu critério, suspender ou encerrar o
                  acesso de um usuário à plataforma caso ele viole estes Termos
                  de Uso ou caso seja exigido por lei.
                </p>
                <h5 className="text-xl font-semibold mb-4">10. Contato</h5>
                <p className="text-slate-400">
                  Para dúvidas sobre estes Termos de Uso, entre em contato
                  conosco pelo canal de suporte disponibilizado na plataforma.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
