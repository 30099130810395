import React from "react";

import aboutImg from "../assets/images/features/1.jpg"

import {FiCheckCircle} from '../assets/icons/vander'

export default function AboutTwo(){
    return(
        <>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className="relative order-1 md:order-2">
                        <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 pe-6 pt-6 lg:ms-8">
                            <img src={aboutImg} className="ltr:rounded-tr-lg" alt=""/>
                        </div>
                    </div>

                    <div className="order-2 md:order-1">
                        <h4 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Controle, analise, classifique, avalie e modere de forma automatizada</h4>
                        <p className="text-slate-400">"O Class2Data nos ajudou a automatizar o monitoramento das aulas, economizando tempo e recursos enquanto mantemos um alto padrão de qualidade."</p>
                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> Diminua a necessidade de analise manual das aulas.</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> Otimize processos e reduza gastos.</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> Escale para milhares de aulas em poucas horas</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}