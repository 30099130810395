import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Navbar from "../components/navbar";
import BannerImg from "../assets/images/banner.png";
import AboutThree from "../components/aboutThree";
import AiFeatures from "../components/aiFeatures";
import AboutOne from "../components/aboutOne";
import AboutTwo from "../components/aboutTwo";
import Footer from "../components/footer";

import "../assets/css/home.css";

import {
  MdOutlinePersonSearch,
  BiBookReader,
  FiUserCheck,
  FiDatabase,
} from "../assets/icons/vander";

export default function IndexTwo() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [location]);

  const workData = [
    {
      icon: BiBookReader,
      title: "Analise & Moderação",
      desc: 'A IA verifica se tudo que foi falado nas aulas está dentro das <span class="Text-Strong">politicas e parâmetros predefinidos</span>, monitorando anormalidades como uma aula que deu errado ou um xingamento/discussão.',
    },
    {
      icon: MdOutlinePersonSearch,
      title: "Auditoria pedagógica",
      desc: 'Verifique pedagogicamente se tudo que foi ensinado está correto, recebendo alertas como <span class="Text-Strong">conteúdo incorreto</span>, fora do contexto, ou sem relação com o tema de aula.',
    },
    {
      icon: FiDatabase,
      title: "Gere dados & Insights",
      desc: 'Transforme suas aulas em dados e insights valiosos, tornando fácil e escalável o <span class="Text-Strong">monitoramento da qualidade</span> e índices de milhares de aulas através de relatórios personalizados para cada interesse.',
    },
    {
      icon: FiUserCheck,
      title: "Controle tudo",
      desc: 'Crie politicas para monitorar o que quiser, como um <span class="Text-Strong">sinal de evasão</span> (aluno falar "pretendo parar com o curso"), <span class="Text-Strong">conflitos de interesse</span> (como um professor tentando vender algo), e análise de tom/sentimento',
    },
  ];
  return (
    <>
      <Navbar />
      <section className="relative table w-full lg:py-40 md:py-36 pt-36 pb-24 overflow-hidden bg-white dark:bg-slate-900">
        <div className="container relative z-1">
          <div className="relative grid lg:grid-cols-12 grid-cols-1 items-center mt-10 gap-[30px]">
            <div className="lg:col-span-7">
              <div className="lg:me-6 lg:text-start text-center">
                <h1 className="font-bold lg:leading-7 leading-6 text-4xl lg:text-6xl mb-5">
                  Inteligência Artificial para monitoramento de aulas online
                </h1>
                <p className="text-lg max-w-xl lg:ms-0 mx-auto">
                  Class2Data é uma IA criada para monitorar a qualidade de aulas
                  e cursos online. Ela realiza análises, validações e moderação
                  de conteúdo, fornecendo alertas e relatórios de forma
                  automatizada.
                </p>

                <div className="mt-6 mb-3">
                  <button className="py-2 px-5 inline-block font-semibold tracking-wide align-middle duration-500 text-base text-center top-[2px] end-[3px] h-[46px] bg-amber-400 hover:bg-amber-500 border border-amber-400 hover:border-amber-500 text-white rounded-md">
                    <a
                      href="https://wa.me/5511967136492?text=Gostaria%20de%20informa%C3%A7%C3%B5es"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Fale com a gente
                    </a>
                  </button>
                </div>
              </div>
            </div>

            <div className="lg:col-span-5">
              <div className="relative after:content-[''] after:absolute lg:after:-top-0 after:-top-10 after:-right-32 after:w-[36rem] after:h-[36rem] after:border-2 after:border-dashed after:border-slate-700/10 dark:after:border-slate-200/10 after:rounded-full after:animate-[spin_120s_linear_infinite] after:-z-1 before:content-[''] before:absolute lg:before:-top-24 before:-top-36 before:-right-56 before:w-[48rem] before:h-[48rem] before:border-2 before:border-dashed before:border-slate-700/10 dark:before:border-slate-200/10 before:rounded-full before:animate-[spin_240s_linear_infinite] before:-z-1">
                <div className="relative after:content-[''] after:absolute lg:after:-top-24 after:-top-10 after:-right-0 after:w-[42rem] after:h-[42rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
                  <img
                    src={BannerImg}
                    className="lg:max-w-none lg:ms-14"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="relative pt-6 md:pb-24 pb-16 overflow-hidden">
        <span className="absolute blur-[200px] w-[500px] h-[500px] rounded-full top-[25%] -start-[20%] bg-gradient-to-tl to-amber-400  from-fuchsia-600 -z-1"></span>
        <span className="absolute blur-[200px] w-[500px] h-[500px] rounded-full bottom-[25%] -end-[20%] bg-gradient-to-tl to-amber-400  from-fuchsia-600 -z-1"></span>
        <div className="container relative md:mt-24 mt-16" id="teste">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Como funciona?
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              A Class2Data permite uma verificação completa de todas as suas
              aulas, ajudando a garantir a qualidade do conteúdo, moderação,
              verificação de anormalidades e relatórios completos!
            </p>
          </div>

          <div className="grid md:grid-cols-2 grid-cols-1 mt-6 gap-6">
            {workData.map((item, index) => {
              const Icon = item.icon;
              return (
                <div className="relative p-6" key={index}>
                  <div className="flex align-middle justify-center items-center w-14 h-14 text-amber-400">
                    <Icon className="w-12 h-12 " />
                  </div>
                  <h5 className="text-xl font-semibold my-5">{item.title}</h5>
                  <p
                    className="text-slate-400"
                    dangerouslySetInnerHTML={{ __html: item.desc }}
                  ></p>
                </div>
              );
            })}
          </div>
        </div>

        <AboutThree />
        <AiFeatures />
        <AboutTwo />
        <AboutOne />

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Você não precisa escolher entre custo, tempo e qualidade
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              A inteligência artificial do Class2Data torna rápido e fácil
              monitorar a qualidade das suas aulas online, entregando insights
              precisos e com eficiência operacional para grandes grupos
              educacionais.
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
